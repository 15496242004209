import { useState } from 'react'
import Type from 'components/type/new'
import { ButtonLink } from 'components/button/new'
import classnames from 'classnames/bind'
import styles from './download.module.scss'
import sleep from 'helpers/sleep'

const cx = classnames.bind(styles)

export const Download = ({ href, text = 'Download' }) => {
  const [loading, setLoading] = useState(false)
  return (
    <ButtonLink
      href={href}
      loading={loading}
      onClick={async (evt) => {
        setLoading(true)
        await sleep(1500)
        setLoading(false)
      }}
    >
      <Type size="m" el="span">
        {text}
      </Type>
    </ButtonLink>
  )
}

export const DownloadGroup = ({
  label = null,
  align = 'right',
  href,
  text = 'Download',
  size,
}) => {
  return (
    <div className={cx('download_group', { align_left: align === 'left' })}>
      {label && <div className={styles['label']}>{label}</div>}
      {href && (
        <div className={styles['action']}>
          <Download href={href} text={text} />
        </div>
      )}
      {size && (
        <div className={styles['size']}>
          <Type size="m" family="mono">
            &nbsp;{size}
          </Type>
        </div>
      )}
    </div>
  )
}
