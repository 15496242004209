import classnames from 'classnames/bind'
import styles from './new.module.scss'
import { Spinner } from 'components/icon'

const cx = classnames.bind(styles)

interface Props {
  loading?: boolean
  block?: boolean
}

export type ButtonProps = NativeAttrs & Props
export type ButtonLinkProps = NativeAttrs &
  Props & {
    href: string
  }

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>

const Loader = () => (
  <div className={styles['loader']}>
    <Spinner />
  </div>
)

export const ButtonLink = ({
  children,
  loading = false,
  href,
  ...props
}: ButtonLinkProps) => {
  return (
    <a
      href={href}
      className={cx('button', 'button_link', { loading })}
      {...props}
    >
      <span className={styles['content']}>{children}</span>
      <Loader />
    </a>
  )
}

export const Button = ({
  children,
  loading = false,
  ...props
}: ButtonProps) => {
  return (
    <button className={cx('button', { loading })} {...props}>
      <span className={styles['content']}>{children}</span>
      <Loader />
    </button>
  )
}

export default Button
