import styles from './banner.module.scss'

export default function Banner({ children, className = '' }) {
  let classNames = styles.banner
  if (className) {
    classNames += ` ${className}`
  }

  return <div className={classNames}>{children}</div>
}
