import Link from 'next/link'
import Type from 'components/type/new'
import Container from 'components/container'
import { DownloadGroup } from './download'
import classnames from 'classnames/bind'
import styles from './section.module.scss'

const cx = classnames.bind(styles)

interface SectionProps {
  variant?: string
}

interface ItemProps {
  href: string
  title: string
}

interface ListProps {
  items: ItemProps[]
}

export function Section({ title, children, variant = null }) {
  return (
    <div className={cx('section', { [`variant_${variant}`]: variant })}>
      <div className={styles['heading']}>
        <Type size="l" el="h2">
          {title}
        </Type>
      </div>
      {children}
    </div>
  )
}

export function List({ items = [] }: ListProps) {
  return (
    <div className={styles['section-list']}>
      <ul>
        {items.map((item, index) => (
          <SectionItem key={index} {...item} />
        ))}
      </ul>
    </div>
  )
}

function SectionItem({ href, title }: ItemProps) {
  return (
    <li>
      <Type size="l" el="span">
        <Link href={href}>{title}</Link>
      </Type>
    </li>
  )
}

export const ProductSection = ({ title = '', children }) => (
  <div className={styles['product_section']}>
    <Container size="support">
      <Section title={title} variant="product">
        {children}
      </Section>
    </Container>
  </div>
)

export const DownloadSection = ({
  url = null,
  filename = '',
  filesize = '8.75MB',
  content = null,
}) => {
  return (
    <div className={cx('download')}>
      <Container size="support">
        <div className={cx('section')}>
          <div className={styles['heading']}>
            <Type size="l" el="h2">
              File
            </Type>
          </div>
          <div className={styles['content']}>
            <Type family="mono" size="m" el="span">
              {filename}
            </Type>
            <Type
              family="mono"
              size="m"
              el="span"
              className={styles['mobile_filesize']}
            >
              {filesize}
            </Type>
          </div>
          <div className={styles['file']}>
            <DownloadGroup align="left" size={filesize} href={url} />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Section
