import { GetStaticProps, GetStaticPropsContext } from 'next'
import Layout from 'components/layout'
import Container from 'components/container'
import Banner from 'components/banner'
import Type from 'components/type/new'
import Link from 'next/link'
import styles from './index.module.scss'
import { Section, List } from 'components/support/section'
import getGridItems from 'lib/support/product_grid'
import { Picture, Image, Source } from 'components/images'
import { ButtonLink } from 'components/button/new'

interface ImageType {
  media: string
  url: string
}

interface CardProps {
  background?: string
  title: string
  href: string
  image?: {
    sizes: ImageType[]
  }
}

function Card({ background, title, href, image = null }: CardProps) {
  const attributes = { className: styles['card'] }
  if (background) {
    attributes['style'] = { backgroundColor: background }
  }
  const defaultImg = image?.sizes[image.sizes.length - 1]
  return (
    <Link href={href} {...attributes}>
      <div className={styles['content']}>
        <Type size="xl" el="h3" className={styles['grid_title']}>
          {title}
        </Type>
      </div>
      {image !== null && (
        <div className={styles['image']}>
          <Picture fit="contain">
            {image.sizes.map(({ url, media }) => (
              <Source key={media} srcSet={url} media={media} lazy />
            ))}
            <Image src={defaultImg.url} lazy alt={title} />
          </Picture>
        </div>
      )}
      <div className={styles['sizer']} />
    </Link>
  )
}

function CardGroup({ background = null, children }) {
  const attributes = { className: styles['card_group'] }
  if (background) {
    attributes['style'] = { backgroundColor: background }
  }
  return (
    <div {...attributes}>
      <div className={styles['card_wrapper']}>{children}</div>
    </div>
  )
}

function GridItem({ featured, ...item }) {
  return featured ? (
    <FeaturedGridItem
      consoles={item.consoles}
      color={item.colors}
      images={item.images}
      {...item}
    />
  ) : (
    <SingleGridItem
      color={item.color}
      console={item.console}
      title={item.title}
      href={item.href}
      {...item}
    />
  )
}

function SingleGridItem({ color, console: product, title, href, ...item }) {
  return <Card title={title} href={href} background={color} {...item} />
}

function FeaturedGridItem({ consoles, color, images }) {
  const productImages = images?.images
  return (
    <CardGroup background={color}>
      {consoles.map(({ title, slug }, index) => (
        <Card
          key={slug}
          title={title}
          href={`/support/${slug}`}
          background="transparent"
          image={productImages[index]}
        />
      ))}
    </CardGroup>
  )
}

function SupportHomePage({ gridItems }) {
  return (
    <Layout
      title="Support"
      meta={{
        title: 'Support',
        description: 'Get support and more information about Analogue products',
      }}
    >
      <Banner className={styles['banner']}>
        <Type size="xl" el="h1">
          Support
        </Type>
      </Banner>
      <Container size="support">
        <div className={styles['cards']}>
          {gridItems.map((item, index) => (
            <GridItem key={`item_${index}`} {...item} />
          ))}
          <Card title="Legacy Products" href="/support/legacy" />
        </div>
        <Section title="General Support">
          <List
            items={[
              { title: 'Contact', href: '/support/contact' },
              { title: 'Returns', href: '/support/general#returns' },
              { title: 'Shipping', href: '/support/general#shipping' },
              { title: 'Repairs', href: '/support/general#repairs' },
            ]}
          />
        </Section>
        <Section title="Developer">
          <List items={[{ title: 'openFPGA', href: '/developer' }]} />
        </Section>
      </Container>
    </Layout>
  )
}

SupportHomePage.theme = 'dark'
export default SupportHomePage

export const getStaticProps: GetStaticProps = async (
  context: GetStaticPropsContext
) => {
  const preview = context.preview
  const gridItems = await getGridItems(preview)
  return {
    props: {
      gridItems,
    },
  }
}
